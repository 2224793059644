import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"
import LanguageSwitcher from "../LanguageSwitcher"
import { meanMenuInit } from './meanmenu-init'
import Breakpoint from 'react-socks'

class Navigation extends React.Component  {

  componentDidMount() {
    meanMenuInit()
  }

  render() {

    const { t } = this.props
    const { localeData } = this.props

    return (
      <>
        <nav>
          <ul className="main-menu">
                <li className="active"><Link to={t('home-link')}>{t('home')}</Link></li>
                <li className="dropdown">{t('restaurant')}
                    <ul>
                      <li><Link to={t('restaurant-link')}>{t('menu')}</Link></li>
                      <li><Link to={t('drink-link')}>{t('drinks')}</Link></li>
                      {/*<li><Link to={t('xmas-menu-link')}>{t('xmas-menu')}</Link></li>*/}
                    </ul>
                </li>
                <li className="dropdown">{t('services')}
                    <ul>
                        {/*<li><Link to={t('sports-rental-link')}>{t('sports-rental')}</Link></li>*/}
                        {/*<li><Link to={t('luxury-car-link')}>{t('luxury-car')}</Link></li>*/}
                        <li><Link to={t('wellness-link')}>{t('wellness')}</Link></li>
                        {/*<li><Link to={t('pool-link')}>{t('pool')}</Link></li>*/}
                    </ul>
                </li>
                <li className="dropdown">{t('hotel')}
                    <ul>
                        <li><Link to={t('rooms-link')}>{t('rooms')}</Link></li>
                        <li><Link to={t('about-us-link')}>{t('about-us')}</Link></li>
                        <li><Link to={t('news-link')}>{t('news')}</Link></li>
                        <li><Link to={t('events-link')}>{t('events')}</Link></li>
                        <li><Link to={t('rules-link')}>{t('rules')}</Link></li>
                    </ul>
                </li>
                {/*<li className="dropdown offers">{t('offers')}
                    <ul>
                        <li><Link to={t('xmas-offer-link')}>{t('xmas-offer')}</Link></li>
                    </ul>
                </li>*/}
                <li className="dropdown">{t('occasion')}
                  <ul>
                    <li><Link to={t('family-days-link')}>{t('family-days')}</Link></li>
                    <li><Link to={t('birthday-link')}>{t('birthday')}</Link></li>
                    <li><Link to={t('conference-link')}>{t('conference')}</Link></li>
                    <li><Link to={t('wedding-link')}>{t('wedding')}</Link></li>
                    <li><Link to={t('kidparty-link')}>{t('kidparty')}</Link></li>
                    {/*<li><Link to={t('xmas-events-link')}>{t('xmas-events')}</Link></li>*/}
                  </ul>
                </li>
                <li className="dropdown carriers">{t('carriers')}
                  <ul>
                    <li><Link to={t('event-manager-link')}>{t('event-manager-carrier')}</Link></li>
                    <li><Link to={t('waiter-link')}>{t('waiter-carrier')}</Link></li>
                    <li><Link to={t('front-office-manager-link')}>{t('front-office-manager-carrier')}</Link></li>
                    <li><Link to={t('deputy-chef-link')}>{t('deputy-chef-carrier')}</Link></li>
                    <li><Link to={t('receptionist-link')}>{t('receptionist-carrier')}</Link></li>
                    <li><Link to={t('janitor-link')}>{t('janitor-carrier')}</Link></li>
                    <li><Link to={t('maid-link')}>{t('maid-carrier')}</Link></li>
                    <li><Link to={t('kitchen-worker-link')}>{t('kitchen-worker-carrier')}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={t('contact-link')}>{t('contact')}</Link>
                </li>
                <Breakpoint large up tagName="li">
                  <LanguageSwitcher localeData={localeData} />
                </Breakpoint>
          </ul>
        </nav>
        <Breakpoint medium down className="hamburgered">
          <LanguageSwitcher localeData={localeData} />
        </Breakpoint>
      </>
    );
  }
};

export default withTranslation()(Navigation)